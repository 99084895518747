@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-family: "segoeui" ;
}
*{
  font-family:"segoeui"
}
.ant-modal-content {
  border-radius: 10px !important;
  /* background: red; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* m-plus-rounded-1c-300 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "segoeui";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/segoeui.ttf"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/segoeui.ttf")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./fonts/segoeui.ttf")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/segoeui.ttf")
      format("ttf"),
    /* Modern Browsers */
      url("./fonts/segoeui.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/segoeui.ttf")
      format("svg"); /* Legacy iOS */
}

